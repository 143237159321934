/** @jsx jsx */
import { useState } from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { jsx, Grid, Box, Heading, Text } from "theme-ui";
import ResizeObserver from "react-resize-observer";

import Layout from "../components/layout";
// import Nav from "../components/nav";
// import SidePanel from "../components/side-panel";
import { Slideshow } from "../components/slideshow";
import BreadCrumbs from "../components/breadcrumbs";

const Product = ({ pageContext, location, data }) => {
  const [width, setWidth] = useState(null);
  // let post, siteTitle, previous, next;
  // if (this.props.preview) {
  //   post = this.props.preview;
  //   siteTitle = "Preview";
  //   previous = null;
  //   next = null;
  // } else {
  //   post = this.props.data.wpgraphql.postBy;
  //   siteTitle = this.props.data.site.siteMetadata.title;
  //   ({ previous, next } = this.props.pageContext);
  // }

  // adapt for preview -> different graphql data
  const { name, description, galleryImages } =
    data?.wpProduct || data?.preview || {};
  const images =
    galleryImages?.nodes?.map(x => ({
      fluid: x?.localFile.childImageSharp.fluid,
    })) || [];
  // console.log(data);
  const seo = {
    metaTitle: name,
    // metaDescription: description,
    // shareImage: images[0].url,
    // article: true,
  };

  const { crumbs } = pageContext?.breadcrumb || {};

  return (
    <Layout seo={seo}>
      <ResizeObserver
        onResize={rect => {
          // console.log("Resized. New bounds:", rect.width, "x", rect.height);
          // console.log("Resized. New bounds:", window.innerWidth, "x", window.innerHeight);
          setWidth(rect.width);
        }}
        // onPosition={rect => {
        //   console.log("Moved. New position:", rect.left, "x", rect.top);
        // }}
      />
      {/* <Nav /> */}

      <Box sx={{ minHeight: "87vh" }}>
        <Grid gap={2} columns={[1, 1, 1, 1, 2, "2fr 3fr"]}>
          {/* <SidePanel setSearchResult={setSearchResult} /> */}
          <Box px={2}>
            <BreadCrumbs data={crumbs} />
            <Slideshow productView images={images} width={width} />
          </Box>

          <Box px={2}>
            <Heading>{name}</Heading>
            <Text
              mr="3rem"
              sx={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {/* <Description>{description}</Description> */}
            {/* specification */}
            {/* TODO: buttons to ask for similar offer and share on FB, instagram */}
          </Box>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Product;

export const query = graphql`
  query ProductQuery($slug: String!) {
    wpProduct(slug: { eq: $slug }) {
      name
      description
      galleryImages {
        nodes {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
`;
