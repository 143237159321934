import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Flex, Box } from "theme-ui";

const BreadCrumbs = ({ data }) => {
  const crumbs = data?.map(item => ({
    pathname: item.pathname,
    label: item.crumbLabel.replace(/-/g, " "),
  })) || [];

  return (
    <>
      <Flex>
        <Box>
          {crumbs.length && (
            <StyledUl>
              {crumbs.map((value, index) => {
                return (
                  <StyledLi key={index}>
                    <Link key={index} to={`${value.pathname}`}>
                      {value.label}{" "}
                    </Link>
                    {index < crumbs.length - 1 && <span> / </span>}{" "}
                  </StyledLi>
                );
              })}
            </StyledUl>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default BreadCrumbs;

const StyledUl = styled.ul`
  display: inline-flex;
  list-style-type: none;
`;

const StyledLi = styled.li`
  > * {
    float: left;
    padding: 0 5px 0 0;
    list-style-type: none;

    color: #404248;
    text-transform: capitalize;
    text-decoration: none;

    :hover {
      color: #0366d6;
      text-decoration: underline;
    }
  }
`;
